import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { McqsService } from 'src/app/_clusters/services/mcqs.service';
import { Store } from '@ngrx/store';
import { TabsetComponent, MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { UserRequested } from 'src/app/_store/actions/auth.actions';
import { CalulatorComponent } from 'projects/cpa/src/app/pages/modal-pages/calulator/calulator.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import Swal from 'sweetalert2';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SpreadDesignerComponent } from 'src/app/spread-designer/spread-designer.component';
import { ModalService } from './modal.service';


@Component({
  selector: 'app-aicpa-header',
  templateUrl: './aicpa-header.component.html',
  styleUrls: ['./aicpa-header.component.scss']
})
export class AicpaHeaderComponent implements OnInit {
  @Input() data: any;
  @ViewChild('staticTabs', { static: true }) staticTabs: TabsetComponent;
  private spreadModalRef: any;
  showOption = 0;
  session_id = '';
  mcqQuestionList = [];
  showNext = false;
  showSubmit = true;
  bookmark = [];
  wctMode = false;
  questionIndex = 0;
  activeClass = 0;
  wctSessionId = '';
  wctQuestion = this.mcqService.wctQuestionList.asObservable();
  attemptQuestion = [];
  wctque = [];
  subjectId: any;
  mainTopicId = [];
  countdown = 0;
  minute = 0;
  hours = 0;
  isMobile: boolean;
  subjectName = '';
  examProgressBar: any;
  modalRef: MDBModalRef;
  showCal = true;
  isCalModal = false;
  tabs: any[] = [
    { title: 'Testlet1', content: 'Testlet1' },
    { title: 'Testlet2', content: 'Testlet2' },
  ];
  isSpreadModalOpen: boolean = false;
  mode: any;
  private scrollPosition: number;

  constructor(
    public route: ActivatedRoute, private modalService: MDBModalService, private ngModalService:NgbModal,
    public router: Router, private store: Store<any>, private deviceService: DeviceDetectorService,
    public mcqService: McqsService, private modalServiceCustom: ModalService) {
    }

  ngOnInit() {  window.scroll({top:0,left:0,behavior:"smooth"});
    // this.route.params.subscribe(params => {
    //   console.log("🚀 ~ file: spread-designer.component.ts:118 ~ SpreadDesignerComponent ~ ngOnInit ~ this.session_id:", this.session_id)
    //   if (params.session_id) {
    //     this.session_id = params.session_id;
    //   }
    // })
    this.modalServiceCustom.modalOpened$.subscribe((modalRef: NgbModalRef) => {
        setTimeout(() => {
            window.scrollTo(0, this.scrollPosition);
        }, 1000);
        // Perform actions when the modal is opened
    });

    this.mode = localStorage.getItem('subjectMode');
    this.isMobile = this.deviceService.isMobile();
    this.mcqService.bookmark.subscribe(data => {
      this.bookmark = data;
    });
    this.mcqService.attemptedQuestion.subscribe(data => {
      this.attemptQuestion = data;
      let count = 0;
      this.mcqQuestionList.forEach(element => {
        if (this.attemptQuestion[element.txt_question_id]) {
          count++;
          this.examProgressBar = Math.round(count / this.mcqQuestionList.length * 100);

        }
      });
    });
    this.wctQuestion.subscribe(data => {
      if (data) {
        this.subjectName = 'Business Enviroment & Concepts';
        this.wctMode = true;
        this.showSubmit = false;
        this.wctSessionId = data.encrypt_session_id;
        this.wctque = data.question_details;
        this.mcqService.wctQuestionData.next(this.wctque[0]);
      }
    });
    this.store.select((store: any) => store.mcq.QuestionList).subscribe(data => {
      if (data) {
        this.session_id = data.session_id_encrypt;
        this.mcqQuestionList = data.questions_list;
        this.subjectId = data.subject_id_encrypt;
        this.mainTopicId = data.maintopic_id_encrypt;
        data.subject_id_encrypt === 'c53f9b7df8fbb22dc12d0e859a5c9734' ? this.subjectName = 'AUDITING & ATTESTATION' :
          data.subject_id_encrypt === '53625d62402e139ccc1e441dea96b90c' ? this.subjectName = 'Business Enviroment & Concepts' :
            data.subject_id_encrypt === 'a1a8cddccb57f50303507da5087ed062' ? this.subjectName = 'Financial Accounting & Reporting' :
              data.subject_id_encrypt === '5fdf99246bcbd70b7fdb6b6b600e6d35' ? this.subjectName = 'Regulation' : '';
      }
    });
    setInterval(() => {
      if (this.countdown === 59) {
        this.countdown = 0;
        this.minute++;
        if (this.minute === 60) {
          this.minute = 0;
          this.hours++;
        }
      }
      this.countdown++;
    }, 1000);
  }
  option(index) {
    this.showOption = index;
  }
  onSelect(title, row) {
    this.showNext = false;
    this.activeClass = title;
    title !== this.questionIndex ? this.mcqService.wctShowAnswer = false : '';
    if (title < this.wctque.length) {
      this.questionIndex = title;
      this.mcqService.wctQuestionData.next(row);
    } else {
      this.questionIndex = 0;
    }

  }
  // need to clean code
  showAnswer() {
    if (this.mcqService.wctTextArea) {
      if (this.questionIndex === (this.wctque.length - 1)) {
        this.mcqService.saveWCTAttempts(this.wctque[this.questionIndex].encrypt_question_id, this.wctSessionId)
          .subscribe((data: any) => {
            if (data && data.status) {
              this.mcqService.wctShowAnswer = true;
              this.showSubmit = true;
            }
          });
      } else {
        this.mcqService.saveWCTAttempts(this.wctque[this.questionIndex].encrypt_question_id, this.wctSessionId)
          .subscribe((data: any) => {
            if (data && data.status) {
              this.mcqService.wctShowAnswer = true;
              this.showNext = true;
            }
          });
      }
    } else {
      Swal.fire({
        text: 'Do you want to skip this question?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#06345b',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          this.mcqService.wctShowAnswer = true;
          this.questionIndex === (this.wctque.length - 1) ? this.showSubmit = true : this.showNext = true;
        }
      });
    }
  }
  nextQuestion() {
    this.questionIndex++;
    this.mcqService.wctTextArea = '';
    this.showNext = false;
    this.mcqService.wctShowAnswer = false;
    this.activeClass = this.questionIndex;
    this.mcqService.wctQuestionData.next(this.wctque[this.questionIndex]);

  }
  activePng1(index) {
    if (this.activeClass === index) {
      return true;
    } else {
      return false;
    }
  }
  click(id) {
    // tslint:disable-next-line: max-line-length
    document.getElementById(id).style.display === 'block' ? document.getElementById(id).style.display = 'none' : document.getElementById(id).style.display = 'block';
    // document.getElementById('question2').scrollIntoView();
  }
  getQuestionList() {
    // if (!this.mcqQuestionList.length) {
    //   this.store.dispatch(new LoadMcqActiveSessionAction(this.mcqService.getSessionId()));
    //   this.store.select((store: any) => store.mcq.QuestionList).subscribe(data => {
    //     if (data) {
    //       this.mcqQuestionList = data.questions_list;
    //     }
    //   });
    // }
  }
  goToQuestion(i) {
    // if (i === 0) {
    //   i++;
    // }
    this.mcqService.questionId.next(i);

  }
  saveFlag(row) {
    this.bookmark[row.txt_question_id] = !this.bookmark[row.txt_question_id];
    //// console.log('book', this.bookmark)
    this.mcqService.bookmark.next(this.bookmark);
    this.mcqService.saveFlag(row.txt_question_id, this.session_id);
  }
  wctSubmitExam() {
    this.mcqService.getWCTReport(this.wctSessionId).subscribe(data => {
      this.router.navigate(['/lms/subject/subjectname/result/53625d62402e139ccc1e441dea96b90c']);
      this.store.dispatch(new UserRequested());
    });
  }
  submitExam() {
    this.session_id = this.mcqService.getSessionId();
    if (this.session_id) {
      this.router.navigate(['/lms/mcq-result', this.session_id]);
      this.store.dispatch(new UserRequested());
    }
  }
  quit() {
    //// console.log('mainId', this.mainTopicId[0])
    const subMode = localStorage.getItem("subjectMode") ? localStorage.getItem("subjectMode") : 'old'
    const baseURL = '/lms/v2/'
    if (this.wctMode) {
      this.router.navigate([baseURL+'subject/subjectname/topic/53625d62402e139ccc1e441dea96b90c']);
    } else {
      if (this.mainTopicId.length === 1) {
        this.router.navigate([baseURL+'subject/subjectname/subtopic', this.subjectId, this.mainTopicId[0]]);
      } else {
        this.router.navigate([baseURL+'subject/subjectname/topic', this.subjectId]);
      }

    }
  }
  openWindow() {
    window.open('https://factory.mileseducation.com/Calculator/Calculator.html',
      '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,width=576,height=300');
  }
  openCal() {
    this.modalRef = this.modalService.show(CalulatorComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog modal-lg modal-side modal-bottom-right ',
      containerClass: '',
      animated: true,
    });
  }
  openSpreadSheetModal(){
    // Save the current scroll position
    this.scrollPosition = window.scrollY;

    this.isSpreadModalOpen = true
    // this.spreadModalRef = this.ngModalService.open(SpreadDesignerComponent,{keyboard:false, backdrop:'static', backdropClass:'d-none',windowClass:'spread-modal-content'})
    this.spreadModalRef = this.modalServiceCustom.open(SpreadDesignerComponent,{keyboard:false, backdrop:'static', backdropClass:'d-none',windowClass:'spread-modal-content'});
    this.spreadModalRef.componentInstance.session_id = this.session_id
    this.spreadModalRef.componentInstance.closed.subscribe((res:any)=>{
      this.isSpreadModalOpen = false
      // Restore the scroll position after the modal is closed
      window.scrollTo(0, this.scrollPosition);
    });
  }
  closeSpreadModal(){
    this.spreadModalRef.close();
    this.isSpreadModalOpen = false
  }
}
